@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
}

#pdf {
  width: 100% !important;
}

:root {
  --black-color: #212121;
  --white-color: #ffffff;
}

body {
  background-color: #f7f8fa;
  scroll-behavior: smooth !important;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f7f7f7;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3498d2;
  border-radius: 10px;
}

.container {
  padding: 0 12px;
  margin: 0 auto;
  width: 100%;
}

.row {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}

.col-lg-6 {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 12px;
}

.sm-colm-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
  padding: 0 12px;
}

.sm-colm-3 {
  flex: 0 0 auto;
  width: 25%;
  padding: 0 12px;
}

.sm-colm-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
  padding: 0 12px;
}

.sm-colm-9 {
  flex: 0 0 auto;
  width: 75%;
  padding: 0 12px;
}

.sm-colm-6 {
  flex: 0 0 auto;
  width: 50%;
  padding: 0 12px;
}

.sm-colm-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
  padding: 0 12px;
}

.sm-colm-12 {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 12px;
}

.sm-colm-4 {
  flex: 0 0 auto;
  width: 33.33333%;
  padding: 0 12px;
}

.sm-img-fluid {
  max-width: 100%;
  height: auto;
}

.sm-align-items-center {
  align-items: center;
}

/* ma-commen-section start */
.smr-commen-section {
  height: 100vh;
}

.smr-commen-section .container,
.smr-commen-section .row {
  height: inherit;
}

.smr-commen-section .row {
  align-items: end;
  margin: 0 -12px;
}

.smr-commen-section .row > div {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 12px;
}

.smr-logsin-form {
  padding: 0 12px;
}

.ma-login-section .smr-logo {
  padding: 0 12px;
  padding-bottom: 300px;
  display: flex;
  height: inherit;
}

.smr-logo img {
  width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-right: 100px;
}

.smr-ctn-inner {
  background-color: var(--white-color);
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  min-height: 80vh;
  border-radius: 24px 24px 0px 0px;
  background: #fafafa;
  padding: 40px;
}

.smr-ctn-inner span.sub-title {
  display: block;
  color: var(--black-color);
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.53px;
  margin-bottom: 10px;
}

.smr-ctn-inner h3,
.smr-ctn-inner h3 span {
  display: inline-block;
  color: var(--black-color);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
}

.smr-ctn-inner h3 span {
  margin-bottom: 28px;
  background: linear-gradient(to right, #1f50a2 0%, #30cfd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.smr-ctn-inner .btn {
  margin-bottom: 20px;
  width: 100%;
  min-height: 56px;
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid var(--black-color);
  transition: all 0.3s ease;
  line-height: 24px;
  text-align: center;
}

.smr-ctn-inner .btn:hover {
  border-color: var(--black-color);
  background-color: transparent;
  color: var(--black-color);
}

.smr-form-control {
  border-radius: 8px;
}

.smr-form-control:focus {
  border-color: #1f50a2;
}

.form-group {
  margin-bottom: 20px;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

/* ma-commen-section End */

.haeder_wrapper {
  background-color: #fff;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 0;
  z-index: 999;
}

.haeder_wrapper .container {
  max-width: 100%;
  padding: 0 30px;
}

.haeder_wrapper .row {
  justify-content: space-between;
}

.search-wrapper {
  margin-bottom: 24px;
}

.smr-hero-banner .smr-hero-text {
  font-size: 42px;
  line-height: 52px;
  color: var(--white-color);
  margin-bottom: 0;
  font-weight: 600;
}

.smr-hero-banner .smr-hero-subtitle {
  font-size: 24px;
  line-height: 52px;
  color: var(--white-color);
  margin-bottom: 0;
  font-weight: 600;
}

.smr-hero-banner {
  padding: 50px 40px;
  min-height: 250px;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #212121;
  margin-bottom: 50px;
  border-radius: 15px;
}

.smr-hero-banner p,
.smr-hero-banner span {
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color);
  margin-bottom: 12px;
  max-width: 50%;
}

.smr-hero-banner button {
  background-color: #3498d2;
}

.table-wrapper table tr:first-child th:first-child {
  background-color: rgba(52, 152, 210, 20%);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-color);
  font-weight: 600;
}

.table-wrapper table tr td {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--black-color);
}

.table-wrapper table tr:nth-last-of-type(2n) {
  background-color: #f7f7f7;
}

.search-wrapper input {
  background-color: var(--white-color);
  width: 100%;
}

.search-wrapper > div {
  max-width: 350px;
  width: 100%;
}

.search-wrapper label + div:hover {
  border-color: #3498d2;
}

.login_logout button {
  background-color: #3498d2;
  color: var(--white-color);
  display: flex;
  border: 1px solid #3498d2;
  /* height: 100%; */
  cursor: pointer;
}

.login_logout button:hover {
  color: #3498d2;
  background-color: var(--white-color);
}

.login_logout {
  display: flex;
  align-items: center;
  gap: 20px;
}

.side-btn-group .sidebar_menuitem .ps-menu-icon img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Step form css start */

.sm-stepForm-wrapper .sm-stepform-title h2 {
  font-size: 38px;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #3498d2;
  margin-bottom: 15px;
}

.sm-stepform-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.sm-stepForm-wrapper .sm-stepform-title h5 {
  border: 1px solid var(--white-color);
  background-color: transparent;
  color: var(--white-color);
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.sm-stepForm-wrapper h2 h5:hover {
  color: var(--black-color);
  background-color: var(--white-color);
}

.sm-stepForm-wrapper h6 {
  font-size: 18px;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--white-color);
  margin-bottom: 20px;
  max-width: calc(100% - 200px);
}

.sm-stepForm-wrapper .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.sm-stepForm-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -12px;
}

.sm-commen-box {
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  padding: 15px 20px;
  /* min-height: 290px; */
  border: 1px solid #3498d2;
  box-shadow: 0 4px 4px 0 rgb(52, 152, 210, 25%);
  margin-bottom: 20px;
  /* overflow-x: auto; */
  /* overflow-y: visible; */
}
.common {
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  padding: 15px 20px;
  /* min-height: 290px; */
  border: 1px solid #3498d2;
  box-shadow: 0 4px 4px 0 rgb(52, 152, 210, 25%);
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: visible;
}

.sm-commen-box img {
  max-width: 100%;
  height: auto;
}

.sm-stepForm-wrapper button {
  border-radius: 6px;
  border: 1px solid var(--black-color);
  background: transparent;
  font-family: "Poppins", sans-serif;
  padding: 8px 18px;
  color: var(--black-color);
  text-align: center;
  font-size: 18px;
  max-width: 49%;
  margin-left: auto;
  flex: 1;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  min-height: 56px;
  line-height: 24px;
  transition: all 0.3s ease;
  box-shadow: none;
}

.sm-stepForm-wrapper button:hover {
  border: 2px solid var(--black-color);
  color: #fff;
  background: var(--black-color);
}

.sm-stepForm-wrapper button:disabled {
  background-color: #3498d2;
  color: #fff;
  opacity: 0.5;
  border-color: #3498d2;
}

.sm-stepForm-wrapper button span {
  font-size: 24px;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.sm-form-control {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.sm-form-control label {
  flex: 0 0 auto;
  width: 50%;
  font-family: "Poppins", sans-serif;
  position: relative;
  font-size: 20px;
  color: #3498d2;
  transform: none !important;
  font-weight: 500;
  text-wrap: balance;
  white-space: pre-wrap;
}
.HIS-header {
  flex: 0 0 auto;
  width: 100%;
  font-family: "Poppins", sans-serif;
  position: relative;
  font-size: 25px;
  color: #3498d2;
  transform: none !important;
  font-weight: 500;
}

.sm-form-control .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  flex: 1;
  width: auto;
  position: static;
  display: inline-block;
}

.sm-form-control label + div,
.sm-form-control label + button,
.sm-form-control label + p,
.sm-form-control label + input[type="file"] + button,
.sm-form-control label + input[type="file"] + div {
  min-width: 251px;
}

.sm-form-control label + input[type="file"] + button {
  max-width: 0;
}
.sm-form-control .MuiCheckbox-root {
  min-width: 251px;
}
.sm-form-control .MuiCheckbox-root .MuiSvgIcon-root {
  height: 45px !important;
  width: 40px !important;
}

.sm-form-control label + input[type="file"] + div {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  max-width: 20%;
  font-style: normal;
  font-weight: 600;
  min-height: 56px;
  border: 1px solid var(--black-color);
  padding: 8px 18px;
  border-radius: 6px;
  line-height: 24px;
}

/* .sm-form-control label + input[type="file"] + div:hover {
  background-color: var(--black-color);
  color: var(--white-color);
} */

.sm-form-control label + input[type="file"] + div p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.sm-form-control label + input[type="file"] + div p + span svg {
  width: 20px;
  height: 20px;
}

.sm-form-control label + input[type="file"] + div:hover p + span svg path {
  fill: var(--black-color);
}

.sm-form-control label + p img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  object-position: center;
}

.sm-stepForm-banner {
  background-color: var(--black-color);
  border-radius: 15px;
  padding: 30px;
  position: relative;
  margin-bottom: 30px;
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div {
  background-color: var(--black-color);
  border-radius: 4px;
  /* border: 2px solid #3498d2; */
  color: var(--white-color);
  box-shadow: 0 4px 4px 0 rgb(52, 152, 210, 25%);
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div svg {
  fill: var(--white-color);
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div + div {
  margin-top: 15px;
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div img {
  max-width: 25px;
  height: 20px;
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div .MuiList-root div {
  color: var(--black-color);
  border-radius: 4px;
  justify-content: end;
  background-color: var(--white-color);
}

.sm-stepForm-wrapper .row > div.sm-colm-3 > div .MuiList-root div + div {
  margin-top: 12px;
}

.sm-stepForm-wrapper
  .row
  > div.sm-colm-3
  > div
  .MuiList-root
  div
  .MuiListItemIcon-root {
  margin-top: 0;
}

.sm-dark-accordian > div:first-child .MuiAccordionSummary-content {
  align-items: center;
  gap: 10px;
}

.sm-list ul li > div,
.sm-list ul li > a {
  align-items: flex-start;
  gap: 12px;
  padding: 7px;
}

.sm-list ul li > div {
  align-items: center;
}

.sm-list ul li > div span,
.sm-list ul li > a span {
  color: var(--black-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.sm-list ul li > div p,
.sm-list ul li > a p {
  color: var(--black-color);
  font-family: "Poppins", sans-serif;
}

.sm-list ul li > div p {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sm-commen-box.sm-img-box {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  height: 290px;
}

button.sm-tooltip {
  border-radius: 50px;
  height: 24px;
  width: 24px;
  text-transform: lowercase;
  font-style: italic;
  padding: 5px;
  font-family: serif;
  margin-left: 12px;
  font-size: 16px;
  border-color: #3498d2;
  background-color: #3498d2;
  color: var(--white-color);
  line-height: 20px;
  min-width: inherit;
  min-height: inherit;
}

.sm-commen-box.sm-indicatore-box {
  /* position: absolute;
  top: 20px;
  right: 20px; */
  /* max-width: 200px; */
  min-height: auto;
  height: auto;
}

.sm-stepform-img {
  flex: 0 0 50%;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 4px;
  /* padding: 12px; */
  height: 100%;
  padding-bottom: 0;
}

.sm-stepform-img img {
  max-width: 100%;
  height: 120px;
  width: 100%;
  display: block;
  /* margin-bottom: 5px; */
  object-fit: contain;
}

.sm-stepform-img p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  color: #3498d2;
  font-weight: 400;
  /* margin-top: 5px; */
  font-family: "poppins", sans-serif;
  white-space: nowrap;
  /* overflow : hidden; */
  text-overflow: ellipsis;
}

.sm-form-control label + p svg {
  width: 1.6em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.sm-form-control label + p svg circle {
  fill: none;
  stroke: #3498d2;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

.general_popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: #ffffff; */
  border-radius: 7px;
  padding: 20px 0;
  min-width: 180px;
}
.bg-red{
  background: red;
}
.bg-white{
  background: #fff;
}

.general_popup .modal_header p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #000000;
}

.general_popup .modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  padding: 0px 20px 10px;
}
.general_popup .modal_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-top: 1px solid #efefef;
  padding: 15px 20px 0px;
  width: 100%;
}

.general_popup .model_dataWrapper {
  padding: 10px 20px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #f7f7f7;
}

.validation_popup .model_dataWrapper {
  flex-direction: column;
  gap: 10px;
  background: none;
}

.general_popup .model-footer {
  width: 100%;
  padding: 20px 20px 0;
  text-align: end;
}

.validation_popup .model-footer {
  padding: 0;
}

.text-white{
  color: #ffffff !important;
}
.text-black{
  color: #000000 !important;
}
@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

/* swiper slider start */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 90%;
  border-radius: 6px 6px 0 0;
  object-fit: cover;
  object-position: top;
}

.swiper-img {
  width: 100%;
  display: block;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  height: 290px;
}

.swiper-img p {
  color: #3498d2;
  font-size: 14px;
  line-height: 1;
  font-family: "poppins", sans-serif;
  font-weight: 400;
  padding: 8px 0px;
  text-align: center;
}

.sm-commen-box.sm-indicatore-box {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.sm-commen-box.sm-indicatore-box > div {
  width: 48%;
  height: 100%;
  display: inline-block;
  min-height: 30px;
  border-radius: 4px;
}

.sm-commen-box.sm-indicatore-box > div:first-child {
  background-color: rgb(114, 205, 23);
}

.sm-commen-box.sm-indicatore-box > div:last-child {
  background-color: red;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 30px;
}

.general_popup .model-footer button {
  font-size: 0;
  line-height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M86.5875 13.4125C86.8785 13.7028 87.1094 14.0476 87.267 14.4273C87.4245 14.8069 87.5056 15.214 87.5056 15.625C87.5056 16.036 87.4245 16.4431 87.267 16.8227C87.1094 17.2024 86.8785 17.5472 86.5875 17.8375L17.8375 86.5875C17.2507 87.1743 16.4548 87.5039 15.625 87.5039C14.7952 87.5039 13.9993 87.1743 13.4125 86.5875C12.8257 86.0007 12.4961 85.2048 12.4961 84.375C12.4961 83.5451 12.8257 82.7493 13.4125 82.1625L82.1625 13.4125C82.4528 13.1215 82.7976 12.8906 83.1773 12.733C83.5569 12.5755 83.964 12.4944 84.375 12.4944C84.786 12.4944 85.193 12.5755 85.5727 12.733C85.9524 12.8906 86.2972 13.1215 86.5875 13.4125Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.4125 13.4125C13.1215 13.7028 12.8906 14.0476 12.733 14.4273C12.5755 14.8069 12.4944 15.214 12.4944 15.625C12.4944 16.036 12.5755 16.4431 12.733 16.8227C12.8906 17.2024 13.1215 17.5472 13.4125 17.8375L82.1625 86.5875C82.7493 87.1743 83.5451 87.5039 84.375 87.5039C85.2048 87.5039 86.0007 87.1743 86.5875 86.5875C87.1743 86.0007 87.5039 85.2048 87.5039 84.375C87.5039 83.5451 87.1743 82.7493 86.5875 82.1625L17.8375 13.4125C17.5472 13.1215 17.2024 12.8906 16.8227 12.733C16.4431 12.5755 16.036 12.4944 15.625 12.4944C15.214 12.4944 14.8069 12.5755 14.4273 12.733C14.0476 12.8906 13.7028 13.1215 13.4125 13.4125Z' fill='black'/%3E%3C/svg%3E%0A");
  top: 25px;
  right: 3px;
  width: 30px;
  height: 30px;
  font-weight: inherit;
  position: absolute;
}

.general_popup .modal_header p {
  font-size: 30px;
  line-height: 40px;
}

.general_popup .model_dataWrapper {
  max-height: 250px;
  overflow: auto;
}
.common-box {
  min-width: 300px;
  min-height: 150px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-box p {
  font-size: 18px;
  font-weight: 700;
  color: #111;
  text-wrap-style: pretty;
}
.green {
  background-color: #41b14e;
}
.red {
  background-color: #d44040;
}
.sm-form-control label + p {
  border: 1px solid #212121;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  max-height: 55px;
}

.sm-uplImg img {
  height: 100%;
  border-radius: 6px 6px 0 0;
  max-height: 259px;
  object-fit: contain;
  margin-bottom: 0;
}

.sm-uplImg-name {
  padding: 5px 0;
  background: #212121;
  border-radius: 0 0 6px 6px;
}

.default-img {
  max-width: 50.5% !important;
  margin: 0 auto;
  height: auto !important;
}

.upload_limit {
  width: auto;
  max-width: 50%;
}

.sm-placeholeder {
  /* width: 230px; */
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Step form css ENd */
/* sm table design start */
.sm-table-wrapper {
  margin-top: 15px;
}

.sm-table-wrapper thead {
  background-color: var(--black-color);
  color: var(--white-color);
}

.sm-table-wrapper thead th {
  /* color: var(--white-color); */
  color: #3498d2;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.sm-table-wrapper thead th:last-child {
  /* color: var(--white-color); */
  color: #3498d2;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}
.dailyPrep-table thead th:last-child {
  width: 50px !important;
}
.sm-table-wrapper table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 5%);
}

.sm-pdfModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf_content {
  max-width: 100%;
  max-height: 800px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pdf_content iframe {
  height: 80vh;
}

.custom_pdf_download {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
}

.pdf_model_header .white-closeBtn {
  font-size: 0;
  line-height: 0;
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M86.5875 13.4125C86.8785 13.7028 87.1094 14.0476 87.2669 14.4273C87.4245 14.8069 87.5056 15.214 87.5056 15.625C87.5056 16.036 87.4245 16.4431 87.2669 16.8227C87.1094 17.2024 86.8785 17.5472 86.5875 17.8375L17.8375 86.5875C17.2507 87.1743 16.4548 87.5039 15.625 87.5039C14.7952 87.5039 13.9993 87.1743 13.4125 86.5875C12.8257 86.0007 12.4961 85.2048 12.4961 84.375C12.4961 83.5451 12.8257 82.7493 13.4125 82.1625L82.1625 13.4125C82.4528 13.1215 82.7976 12.8906 83.1773 12.733C83.557 12.5755 83.964 12.4944 84.375 12.4944C84.786 12.4944 85.1931 12.5755 85.5727 12.733C85.9524 12.8906 86.2972 13.1215 86.5875 13.4125Z' fill='%233498D2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.4125 13.4125C13.1215 13.7028 12.8906 14.0476 12.733 14.4273C12.5755 14.8069 12.4944 15.214 12.4944 15.625C12.4944 16.036 12.5755 16.4431 12.733 16.8227C12.8906 17.2024 13.1215 17.5472 13.4125 17.8375L82.1625 86.5875C82.7493 87.1743 83.5451 87.5039 84.375 87.5039C85.2048 87.5039 86.0007 87.1743 86.5875 86.5875C87.1743 86.0007 87.5039 85.2048 87.5039 84.375C87.5039 83.5451 87.1743 82.7493 86.5875 82.1625L17.8375 13.4125C17.5472 13.1215 17.2024 12.8906 16.8227 12.733C16.4431 12.5755 16.036 12.4944 15.625 12.4944C15.214 12.4944 14.8069 12.5755 14.4273 12.733C14.0476 12.8906 13.7028 13.1215 13.4125 13.4125Z' fill='%233498D2'/%3E%3C/svg%3E%0A");
}

.pdf_model_header {
  display: flex;
  justify-content: space-between;
  background-color: var(--white-color);
  flex-wrap: wrap;
  border-radius: 6px 6px 0 0;
  padding: 7px 12px;
}

.pdf_model_header p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: "poppins", sans-serif;
}

.sm-battery-img {
  max-width: 300px;
  margin: 0 auto;
}

.listPrimary-text {
  color: #3498d2;
}

.sm-Tabs-heading {
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  font-weight: 600;
  font-family: "poppins", sans-serif;
  color: #3498d2;
  padding-top: 30px;
  margin-bottom: 30px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #3498d2;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #3498d2;
}

.sm-form-wrapper {
  border-radius: 20px;
  background-color: #212121;
  max-width: 60%;
  margin: 0 auto;
  padding: 30px;
}

.sm-form-FullWidth {
  max-width: 100%;
  border: 2px solid #3498d2;
  background-color: var(--white-color);
}

.list-label {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  flex: 0 0 50%;
  max-width: 50%;
  font-family: "poppins", sans-serif;
  padding: 12px;
}

.list-value {
  color: #ffffff;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border: 1px solid #ffffff;
  border-radius: 4px;
  font-family: "poppins", sans-serif;
  width: auto;
}

.list-grp {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  align-items: center;
  margin-bottom: 12px;
}

.list-img {
  max-width: 50%;
  flex: 0 0 auto;
  border: 1px solid #ffffff;
  border-radius: 6px;
}

.list-img img {
  display: block;
  object-fit: cover;
  object-position: center;
  height: 200px;
}

.sm-form-FullWidth .list-value {
  border-color: var(--black-color);
  color: var(--black-color);
}

.sm-form-FullWidth .list-label {
  color: #3498d2;
}

.sm-bindetails p {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  flex-grow: 1;
  border-color: rgb(0 0 0 / 23%);
  padding: 16.5px 14px;
}

.sm-bindetails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.sm-bindetails label {
  width: 50%;
  font-family: "Poppins", sans-serif;
  position: relative;
  font-size: 20px;
  color: #3498d2;
  transform: none !important;
  font-weight: 500;
}

/* Media Query start */
@media (min-width: 576px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 1366px) {
}

@media (max-width: 1280px) {
  .container {
    max-width: 1140px;
  }

  .ma-login-section .smr-logo {
    padding-bottom: 200px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .ma-login-section .smr-logo {
    padding-bottom: 150px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 720px;
  }

  .smr-commen-section {
    padding: 50px 0;
    height: auto;
  }

  .smr-ctn-inner {
    border-radius: 20px;
  }

  .ma-login-section .smr-logo {
    padding-bottom: 50px;
  }

  .smr-ctn-inner {
    max-width: 100%;
    margin: 0 auto;
    min-height: auto;
  }

  .smr-commen-section .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 540px;
  }

  .smr-ctn-inner h3,
  .smr-ctn-inner h3 span {
    font-size: 20px;
    line-height: 1.3;
  }

  .smr-ctn-inner .btn {
    font-size: 14px;
    line-height: 20px;
  }

  .smr-ctn-inner {
    padding: 20px 30px;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
  }
}

/* Media Query End */
/* loader */
.report_loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.report_loader::before,
.report_loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #212121;
  animation: prixClipFix 2s linear infinite;
}

.report_loader::after {
  border-color: #3498d2;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 5px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.sm-commen-table {
  padding-top: 30px;
}

.sm-tootip-true {
  min-height: 24px !important;
  height: 24px;
  width: 24px;
  max-width: 24px !important;
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  display: inline-block;
  margin-left: auto;
}

.sm-tootip-true svg {
  fill: #14ad26;
}

.sm-tootip-false svg {
  fill: #ff0000;
}

.graph_heading {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.graph_filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  gap: 15px;
}

.graph_header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  gap: 15px;
}

.graph_grid {
  display: grid;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: (1, 1);
  padding: 10px;
  gap: 15px;
}

.graph_heading h4 {
  color: #3498d2;
  font-size: 22px;
  font-weight: 500;
}

.OEM__control,
.react-select {
  width: 150px !important;
  z-index: 98 !important;
}

.startDate {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 150px;
  height: 38px !important;
}
.dateRange {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  min-width: 150px;
  width: fit-content;
  height: 38px !important;
}

.OEM__dropdown-indicator,
.OEM__indicator-separator {
  display: none !important;
}

.react-datepicker-popper {
  z-index: 99 !important;
  position: absolute !important;
}

.react-datepicker__input-container input::placeholder {
  color: #858585;
}

.react-datepicker__calendar-icon {
  width: 18px !important;
  height: 18px !important;
  padding: 10px 5px !important;
  fill: #ccc !important;
}

.react-datepicker__navigation {
  background-color: transparent !important;
  border: none !important;
  color: #ccc !important;
  min-height: 38px !important;
}

.react-datepicker__close-icon {
  width: 18px !important;
  height: 18px !important;
  padding: 10px 5px !important;
  border: none !important;
  min-height: 38px !important;
  min-width: 28px !important;
}

.react-datepicker__close-icon:hover {
  background-color: transparent !important;
}

.react-datepicker__close-icon::after {
  /* content: "✕"; */
  font-size: 18px;
  color: #ccc;
  background-color: white !important;
}

.react-select__menu {
  z-index: 9999 !important;
}

/* report template */
.imageGrid {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
  border: 1px solid #000000;
  padding: 10px;
  margin-inline: auto;
}

.imageGrid-QIS {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 80%;
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
  border: 1px solid #000000;
  padding: 10px;
  margin-inline: auto;
}

.gridItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  height: 100%;
  width: 100%;
}

.gridItems img {
  object-fit: cover;
  height: 225px;
  width: 100%;
}
.QIS-img img {
  object-fit: cover;
  height: 300px !important;
  width: 100%;
}

.DownloadButton {
  border-radius: 5px;
  border: 1px solid black;
  padding-inline: 22px;
  min-height: 55px;
  /* width: 310px; */
  /* background-color: transparent; */
  /* transition: all 0.3s ease-in-out; */
  color: #212121;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DownloadButton:hover,
.DownloadButton:disabled {
  background-color: #212121 !important;
  color: white !important;
}

.tableFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-inline: auto;
  flex-direction: row;
}

.tableFlex2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
}

.tableFlex2 img {
  width: 480px;
}

.Energy {
  margin-top: 10px;
  width: 95%;
  display: flex;
  justify-content: flex-start;
}

.Energy h1 {
  font-size: 25px;
  font-weight: 500;
  color: #3498d2;
  margin-top: 5px;
}

.Energy2 {
  margin-top: 20px;
  width: 95%;
  display: flex;
  justify-content: center;
}

.Energy2 h1 {
  font-size: 25px;
  font-weight: 500;
  color: #3498d2;
  margin-top: 15px;
}

.paragraph {
  width: 100%;
  margin-top: 20px;
}

.paragraph p {
  font-size: 16px;
  text-align: justify;
}

@media (max-width: 430px) {
  .graph_heading,
  .graph_filter {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.MuiTableCell-root {
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
}

.start_end_date {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .EnergyChart .apexcharts-legend {
  bottom: 60px !important;
  left: 84px !important;
  border: 1px solid #ccc !important;
  z-index: 300 !important;
  font-size: 10px !important;
  height: fit-content;
  width: 135px !important;
  align-items: flex-start !important;
  background: #e0e0e0;
  overflow: hidden;
} */

.EnergyChart .apexcharts-legend .apexcharts-legend-series {
  align-items: center !important;
  display: flex;
  position: relative;
  font-size: 10px !important;
}

/* .EnergyChart .apexcharts-legend::before {
  content: "";
  text-align: center;
  font-size: 13px !important;
} */

.EnergyChart .apexcharts-legend .apexcharts-legend-marker {
  height: 2px !important;
}

.EnergyChart .apexcharts-legend .apexcharts-legend-marker span {
  height: 2px !important;
}

.Nyquist {
  width: 100%;
}

/* .Nyquist .apexcharts-legend {
  right: 20px !important;
  top: 61px !important;
  border: 1px solid #ccc !important;
  z-index: 300 !important;
  height: fit-content !important;
  display: flex;
  align-items: flex-start;
  background: #e0e0e0;
  overflow: hidden;
} */

.Nyquist .apexcharts-legend .apexcharts-legend-series {
  align-items: center !important;
  display: flex;
}

.Nyquist .apexcharts-legend .apexcharts-legend-marker {
  height: 2px !important;
  position: relative;
  border-radius: 2px !important;
}

.Nyquist .apexcharts-legend .apexcharts-legend-marker::before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: inherit;
  position: absolute;
  left: 30%;
  top: -70%;
}

.DVA {
  width: 100%;
}

/* .DVA .apexcharts-legend {
  right: 32px !important;
  top: 41px !important;
  border: 1px solid #ccc !important;
  z-index: 300 !important;
  height: fit-content !important;
  display: flex;
  align-items: flex-start;
  position: absolute;
  padding-right: 0px;
  background: #e0e0e0;
  overflow: hidden;
} */

/* .DVA .apexcharts-legend .apexcharts-legend-series {
  align-items: center !important;
  display: flex;
}

.DVA .apexcharts-legend .apexcharts-legend-marker {
  position: relative;
  border-radius: 2px !important;
}

.DVA .apexcharts-legend .apexcharts-legend-marker {
  border-radius: 50% !important;
  height: 5px !important;
  width: 5px !important;
} */

.Power_Rate {
  height: 300px !important;
}

/* .Power_Rate .apexcharts-legend {
  right: 104px !important;
  top: 30px !important;
  border: 1px solid #ccc !important;
  z-index: 300 !important;
  height: fit-content !important;
  display: flex;
  align-items: flex-start;
  position: absolute;
  background: #e0e0e0;
  overflow: hidden;
} */

.Power_Rate .apexcharts-legend .apexcharts-legend-series {
  align-items: center !important;
  display: flex;
}

.Power_Rate .apexcharts-legend .apexcharts-legend-marker {
  position: relative;
  border-radius: 2px !important;
}

.Power_Rate .apexcharts-legend .apexcharts-legend-marker {
  border-radius: 10% !important;
  height: 3px !important;
  left: -5px !important;
}
.Nyquist .apexcharts-legend-marker,
.DVA .apexcharts-legend-marker {
  border-radius: 10% !important;
  /* height: 3px !important; */
  left: -5px !important;
}
.EnergyChart .apexcharts-legend-marker {
  border-radius: 10% !important;
  /* height: 3px !important; */
  left: -10px !important;
}
.EnergyChart .apexcharts-legend-text {
  letter-spacing: 2.29px !important;
}
.apexcharts-grid {
  border: 1px solid black !important;
}

.container-graph {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 6px;
  justify-content: center;
  align-items: center;
}
.hide-tab {
  display: none;
}
.item {
  /* border: 1px solid #000; */
  padding: 2px;
  text-align: center;
  font-size: 17px;
}

.sub-item {
  font-size: 17px;
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  min-width: 85px !important;
  text-wrap: nowrap;
}

.sub-item:first-child {
  margin-bottom: 10px;
}

.tooltip {
  padding: 8px;
  text-align: left;
}

.green {
  background-color: #14ad26;
  color: #e8e8e8;
}

.red {
  background-color: red;
  color: #e8e8e8;
}

.apexcharts-yaxis-title {
  margin-left: 5px !important;
}

.custom-chart {
  width: 95% !important;
  min-height: 100vh;
}

.Inner_padding {
  padding-left: 40px;
  padding-right: 40px;
}

/* passport UI */

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: row;
}

.headText {
  font-size: 10;
  letter-spacing: 1.5;
  color: "#3498d2";
  font-weight: 900;
}

.border {
  border: 1px solid black;
}

.jusify-between {
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

.w-50 {
  width: 50%;
}

.w-full {
  width: 100%;
}
.w-95 {
  width: 95%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 72%;
}

.passport {
  width: 80vw;
  margin-inline: auto;
}

/* .passport-img {
  max-width: 300px;
  max-height: 350px;
} */

.bold {
  font-weight: 500;
}

.start {
  justify-content: flex-start;
  align-items: flex-start;
}

.gap-8 {
  gap: 16px;
}
.gap-4 {
  gap: 8px;
}

.width {
  min-width: 120px;
}

.table {
  border: 1px solid black;
}

.con {
  display: grid;
  grid-template-columns: 25% 28%;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.con p,
.w-full h3 {
  color: black;
}

.con p {
  text-align: left;
  /* border: 1px solid red; */
  font-size: 16px;
  font-weight: 600;
}

.footer-con {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 20% 16%;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
}

.footer-con p {
  text-align: center;
  cursor: pointer;
  padding: 5px;
  text-wrap: nowrap;
}

/* .not-active {
  border: 1px solid black;
  background-color: #3498d2;
  color: white;
}

.active {
  background-color: black;
  border-top: 1px solid #3498d2;
  color: white;
} */

.not-active {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.active {
  background-color: #070707;
  border-top: 1px solid black;
  color: white;
}
.custom-chart {
  min-height: 100vh;
  position: relative;
}

.page_number {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 15px;
}

.page_number span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  font-weight: 700;
}

.page_number4 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 15px;
}

.page_number4 span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 700;
}

.Cyclechart .apexcharts-text tspan {
  font-weight: 800;
}

/* schedule */
.schedule_grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.grid_items {
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.grid_head {
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.operator_title {
  font-size: 30px !important;
}

.graph-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}

.graph-item {
  /* background-color: green; */
  padding: 20px;
  text-align: center;
  color: black;
  text-wrap: nowrap;
}

/* .MuiBox-root {
  height: 100vh !important;
} */

.Mobilereps {
  margin-top: 20px;
}
.MuiCircularProgress-root {
  color: #3498d2 !important;
}
.container-modal {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
  align-items: center;
  width: 100%;
  min-width: 400px;
  font-size: 18px;
  color: #070707 !important;
  font-weight: 500 !important;
  opacity: 0.7 !important;
}
.container-modal p {
  margin-bottom: 10px;
}
.MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}
.MuiDialogTitle-root h2 {
  font-size: 22px;
  font-weight: 600;
}
.MuiDialogContent-root {
  padding-top: 20px !important;
}
.pagination-div {
  width: 100%;
  margin-inline: auto;
  padding: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.battery-Pagination {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #070707;
  list-style: none;
}
.battery-Pagination li {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding-top: 2px;
  text-align: center;
}
.battery-Pagination li a {
  display: block;
  height: 100%;
  width: 100%;
  margin: auto;
}
.battery-Pagination-active {
  padding-top: 2px;
  background-color: black;
  color: #fff;
}
.sm-stepForm-wrapper .contained {
  border-radius: 6px;
  border: 0px solid var(--black-color);
  background: transparent;
  font-family: "Poppins", sans-serif;
  padding: 8px 18px;
  color: var(--black-color);
  text-align: center;
  font-size: 18px;
  max-width: 100% !important;
  margin-left: auto;
  flex: 1;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  min-height: 350px;
  line-height: 24px;
  transition: all 0.3s ease;
  box-shadow: none;
  width: 100%;
}
.sm-stepForm-wrapper .contained:hover {
  border: 0px solid var(--black-color) !important;
  color: #fff;
  background: transparent;
}
.password-icon {
  position: absolute;
  right: 16px;
  top: 31%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.width_inc {
  min-width: 400px !important;
  padding: 00px !important;
  /* margin-block: 20px; */
}
@media screen and (max-width: 1300px) {
  .gridItems img {
    object-fit: cover;
    height: 130px;
    width: 100%;
  }

  .Report_desc {
    font-size: 12px;
  }

  .Energy {
    margin-top: 5px !important;
  }

  .tableFlex {
    margin-top: 5px !important;
  }

  .Mobilereps {
    margin-top: 5px;
  }

  .Mobilereps table {
    height: 150px !important;
  }
}
.facility_graph {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1250px) {
  .graph_heading {
    flex-direction: column;
  }
  .graph_header {
    flex-direction: column;
  }
  .hide-tab {
    display: block;
  }
  .container-graph {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 6px;
    justify-content: center;
    align-items: center;
  }
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  /* gap: 5px; */
  margin: 0;
  padding: 6px 8px;
 width: fit-content;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); 
}
.tooltip-header {
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 5px;
  background-color: transparent;
  /* padding: 4px 6px 2px; */
  border-bottom: none;
  width: 100%;
  color: #070707;
}

.tooltip-value {
  /* padding: 6px 10px; */
  width: 100%;
  color: #070707;
  font-size: 13px;
  text-align: left;
  font-weight: 800;
}
.tooltip-prefix {
  width: 100%;
  color: #070707;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
}

/* .tooltip-value:not(:first-child) {
  padding: 1px 10px 8px 10px;
} */
.custom-tooltip {
  background-color: #333;
  color: #fff;
  padding: 0px !important;
  border-radius: 6px;
  font-size: 14px;
  overflow: hidden;
  border: 1px solid gray;
}

.menu-btn {
  display: none !important;
}
.submitField-btn {
  width: 49%;
  margin-left: auto;
}
.common-kpi {
  padding: 15px 20px !important;
}
.passport-images,
.passport-table {
  width: 80vw;
  flex-wrap: wrap;
  /* gap: 60px; */
  row-gap: 30px;
  column-gap: 60px;
}
.packId {
  font-size: 35px;
  text-align: center;
  font-weight: 700;
  color: #3498d2;
  margin-inline: auto;
  margin-top: 25px;
}
.desktop_nav {
  display: block;
}
.mobile_nav {
  display: none;
}
.legend1 .apexcharts-legend {
  margin-left: 30px;
}
.legend2 .apexcharts-legend {
  margin-left: 40px;
}

.noAccess {
  border-radius: 15px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
  border: 2px solid #3498d2;
  color: #3498d2;
  border-radius: 16px;
  margin: 25px;
}
/* Responsive */
@media screen and (max-width: 768px) {
  .menu-btn {
    display: block !important;
  }
  .submitField-btn {
    width: 100%;
  }
  .login-logo img {
    margin: 0px auto 35px !important;
  }
  .sm-colm-3 {
    flex: 0 0 auto;
    width: 100%;
    padding: 12px;
  }
  .sm-colm-9 {
    width: 100%;
  }
  .sm-form-control label {
    width: 100%;
  }
  .sm-form-control label + div,
  .sm-form-control label + button,
  .sm-form-control label + p,
  .sm-form-control label + input[type="file"] + button,
  .sm-form-control label + input[type="file"] + div {
    width: 100%;
    max-width: none;
  }
  .sm-stepForm-wrapper button {
    max-width: 100%;
  }
  .MuiBox-root {
    overflow-y: "scroll";
    -ms-overflow-style: none;
    scroll-behavior: none;
  }
  .MuiBox-root::-webkit-scrollbar {
    display: none;
  }
  .sm-colm-4 {
    width: 50%;
  }
  /* .imageGrid {
    grid-template-columns: 100%;
  } */
  /* .tableFlex {
    flex-direction: column;
  } */
  .Inner_padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .passport {
    width: 100vw;
    overflow-x: hidden;
  }
  .passport-images,
  .passport-table {
    gap: 30px;
  }
  .passport-table-subdiv {
    width: 100%;
  }
  .packId {
    font-size: 28px;
  }
  .w-80 {
    width: 79%;
  }
  .noAccess {
    font-size: 1.5rem;
    padding: 15px;
    margin: 15px;
  }
}
@media screen and (max-width: 426px) {
  .sm-colm-4 {
    width: 100%;
    margin-block: 10px;
  }
  .login_logout {
    justify-content: space-between;
    width: 100%;
  }
  .haeder_wrapper .row {
    gap: 15px;
  }
  .graph-item {
    padding: 14px;
  }
  .common-kpi {
    padding: 15px 8px !important;
  }
  .passport-images img {
    width: 100% !important;
    height: 100% !important;
  }
  .packId {
    font-size: 24px;
  }
  .desktop_nav {
    display: none !important;
  }
  .mobile_nav {
    display: block !important;
  }
  .noAccess {
    font-size: 1rem;
    padding: 10px;
    margin: 10px;
    margin-top: 25px;
  }
}
